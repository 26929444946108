import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { DataDeletionPolicyComponent } from './pages/data-deletion-policy/data-deletion-policy.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { HomeComponent } from './pages/newui-components/home/home.component';


const routes: Routes = [
  { path: '', redirectTo: 'campaign', pathMatch: 'full' },
  { path: 'aboutus', component: AboutComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'datadeletionpolicy', component: DataDeletionPolicyComponent },
  { path: 'termsofservice', component: TermsOfServiceComponent },
  { path: 'home', component: HomeComponent },
  // lazy loading
  { path: 'campaign', loadChildren: () => import('./pages/newui-components/campaign/campaign.module').then(m => m.CampaignModule) },
  { path: 'giveanywhere', loadChildren: () => import('./pages/newui-components/campaign/campaign.module').then(m => m.CampaignModule) },
  //{ path: 'gogive', loadChildren: () => import('./pages/newui-components/campaign/campaign.module').then(m => m.CampaignModule) },
  //{ path: 'givenow', loadChildren: () => import('./pages/newui-components/campaign/campaign.module').then(m => m.CampaignModule) },
  //{ path: 'home', loadChildren: () => import('./pages/newui-components/new-ui-home/home.module').then(m => m.HomeModule) },
  { path: 'shop', loadChildren: () => import('./pages/newui-components/new-ui-shop/shop.module').then(m => m.ShopModule) },
  { path: 'event', loadChildren: () => import('./pages/newui-components/new-ui-events/event.module').then(m => m.EventModule) },
  { path: 'auction', loadChildren: () => import('./pages/newui-components/new-ui-auctions/auctions.module').then(m => m.AuctionsModule) },
  { path: 'raffle', loadChildren: () => import('./pages/newui-components/new-ui-raffle/raffle.module').then(m => m.RaffleModule) },
  { path: 'contact', loadChildren: () => import('./pages/newui-components/mail/mail.module').then(m => m.MailModule) },
  { path: 'referral', loadChildren: () => import('./pages/newui-components/referral/referral.module').then(m => m.ReferralModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
