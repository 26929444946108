<header class="top-header" *ngIf="fatchedParams.organizationid || fatchedParams.qrcode_id">
    <section class="top-navbar">
        <article class="navbar-box">
            <div class="container-new">
                <div class="row">
                    <!-- Brand Image Logo col -->
                    <div class="col-lg-2 col-md-2 col-sm-6 col-5 align-self-center order-md-0 order-sm-0 order-0">
                        <div class="brand-logo">
                            <!-- <a class="brand-logo-link" *ngIf="this.campaignsData?.account_type === 'organization'">
                                <img [src]="campaignsData?.organization_logo" alt="GoGiveAnywhere" class="img-fluid brand-image"/>
                            </a>
                            <a class="brand-logo-link" *ngIf="this.campaignsData?.account_type !== 'organization'">
                                <img [src]="campaignsData?.individual_imageurl" alt="GoGiveAnywhere" class="img-fluid brand-image"/>
                            </a> -->
                            <a class="brand-logo-link">
                                <span *ngIf="!this.campaignsData?.organization_logo">
                                    <img src="../../../../assets/images/logo.png" alt="GoGiveAnywhere" class="img-fluid brand-image"/>
                                </span>
                                <span *ngIf="this.campaignsData?.organization_logo">
                                    <img [src]="this.campaignsData?.organization_logo" alt="GoGiveAnywhere" class="img-fluid brand-image scanQrcodeImage"/>
                                </span>
                            </a>
                        </div>
                    </div>
                    <!-- /Brand Image Logo Col-->
                    <!-- Primary Menu Col-->
                    <div class="col-lg-8 col-md-8 col-sm-0 col-0 align-self-center order-md-1 order-sm-2 order-2">

                        <!-- For Organization -->
                        <div class="primary-menu headerWhitemenu" *ngIf="this.campaignsData && this.campaignsData?.account_type === 'organization'">
                          <ul class="primary-menu-list list-group align-items-center">

                            <li class="list-group-item">
                              <a [routerLinkActive]="['active']" [routerLink]="['/campaign']" [queryParams]="{qrcode_id: fatchedParams.qrcode_id, organizationid: fatchedParams.organizationid}" class="list-group-link">
                                <img src="../../../../assets/images/Newui-image/wallet.png" class="img-fluid MenuiconImage" /> <span>Donate</span>
                              </a>
                            </li>

                            <li class="list-group-item" *ngIf="common.storeNavigationData?.isStoreNavigationLinkVisible">
                              <a [routerLinkActive]="['active']" [routerLink]="common.storeNavigationData?.routerLink" [queryParams]="common.storeNavigationData?.queryParams" class="list-group-link">
                                <img src="../../../../assets/images/Newui-image/store.png" class="img-fluid MenuiconImage" /> <span>Shop</span>
                              </a>
                            </li>

                            <li class="list-group-item" *ngIf="common.eventNavigationData?.isEventNavigationLinkVisible">
                              <a [routerLinkActive]="['active']" [routerLink]="common.eventNavigationData?.routerLink" [queryParams]="common.eventNavigationData?.queryParams" class="list-group-link">
                                <img src="../../../../assets/images/Newui-image/events.png" class="img-fluid MenuiconImage" /> <span>Events</span>
                              </a>
                            </li>

                            <li class="list-group-item" *ngIf="common.raffleNavigationData?.isRaffleNavigationLinkVisible">
                              <a [routerLinkActive]="['active']" [routerLink]="common.raffleNavigationData?.routerLink" [queryParams]="common.raffleNavigationData?.queryParams" class="list-group-link">
                                <img src="../../../../assets/images/Newui-image/raffle.png" class="img-fluid MenuiconImage" /> <span>Raffles</span>
                              </a>
                            </li>

                            <li class="list-group-item" *ngIf="common.auctionNavigationData?.isAuctionNavigationLinkVisible">
                              <a [routerLinkActive]="['active']" [routerLink]="common.auctionNavigationData?.routerLink" [queryParams]="common.auctionNavigationData?.queryParams" class="list-group-link">
                                <img src="../../../../assets/images/Newui-image/auctions.png" class="img-fluid MenuiconImage" /> <span>Auctions</span>
                              </a>
                            </li>
                            <li class="list-group-item moreiconsParentmenu">
                              <a class="list-group-link not-sm-device">
                                <i class="bi bi-three-dots-vertical me-2"></i><span>More</span>
                              </a>
                              <ul class="primary-menu-list list-group moreiconsSubMenu">
                                <li class="list-group-item" *ngIf="campaignsData">
                                  <a [routerLinkActive]="['active']" [routerLink]="['/contact']" [queryParams]="{qrcode_id: fatchedParams.qrcode_id, organizationid: fatchedParams.organizationid}" class="list-group-link">
                                    <img src="../../../../assets/images/Newui-image/mailing.png" class="img-fluid MenuiconImage" /> <span>Contact List</span>
                                  </a>
                                </li>

                                <li class="list-group-item" hidden *ngIf="campaignsData">
                                  <a [routerLinkActive]="['active']" [routerLink]="['/referral']" [queryParams]="{qrcode_id: fatchedParams.qrcode_id, organizationid: fatchedParams.organizationid}" class="list-group-link">
                                    <img src="../../../../assets/images/Newui-image/referral-program.png" class="img-fluid MenuiconImage" /> <span>Referral</span>
                                  </a>
                                </li>

                                <li class="list-group-item">
                                  <a [routerLinkActive]="['active']" [routerLink]="['aboutus']" [queryParams]="fatchedParams" class="list-group-link">
                                    <img src="../../../../assets/images/Newui-image/about-us.png" class="img-fluid MenuiconImage" /> <span>About Us</span>
                                  </a>
                                </li>
                              </ul>
                            </li>

                            <!-- <li class="list-group-item have-child">
      <a href="javascript:void(0);" class="list-group-link parent-link"><img src="../../../../assets/images/Newui-image/user.png" class="img-fluid MenuiconImage"/> <span class="me-2">Use My Wallet</span><i class="bi bi-caret-down-fill"></i></a>
      <div class="sub-child-menu sm-sub-menu-list">
          <div class="sub-child-list">
              <div class="links-creater ">
                  <a href="#" class="sub-menu-link ps-4">Sign In</a>
              </div>

          </div>
      </div>
  </li> -->


                          </ul>
                        </div>

                        <!-- For Individual -->
                        <div class="primary-menu headerWhitemenu" *ngIf="this.campaignsData && this.campaignsData?.account_type !== 'organization'">
                            <ul class="primary-menu-list list-group">

                                <li class="list-group-item">
                                    <a [routerLinkActive]="['active']" [routerLink]="['individualhome']" [queryParams]="{qrcode_id: fatchedParams.qrcode_id, organizationid: fatchedParams.organizationid}" class="list-group-link">
                                        <img src="../../../../assets/images/Newui-image/wallet.png" class="img-fluid MenuiconImage"/> <span>Donate</span>
                                    </a>
                                </li>

                                <!-- <li class="list-group-item have-child">
                                    <a href="javascript:void(0);" class="list-group-link parent-link"><img src="../../../../assets/images/Newui-image/user.png" class="img-fluid MenuiconImage"/> <span class="me-2">Use My Wallet</span><i class="bi bi-caret-down-fill"></i></a>
                                    <div class="sub-child-menu sm-sub-menu-list">
                                        <div class="sub-child-list">
                                            <div class="links-creater ">
                                                <a href="#" class="sub-menu-link ps-4">Sign In</a>
                                            </div>

                                        </div>
                                    </div>
                                </li> -->


                            </ul>
                        </div>
                    </div>
                    <!-- /Primary Menu Col-->
                    <!-- All Action Menu Col-->
                    <div class="col-lg-2 col-md-2 col-sm-6 col-7 align-self-center order-md-2 order-sm-1 order-1">
                        <div class="all-top-action-menu">
                            <!-- Toggle Action -->
                            <div class="toggle-menu">
                                <button class="btn toggle-action-btn">
                                    <i class="bi bi-list"></i>
                                </button>
                            </div>
                            <!-- /Toggle Action -->
                            <!-- Search Action -->
                            <!-- <div class="search-btn">
                                <button class="btn search-action-btn">
                                    <i class="bi bi-search"></i>
                                </button>
                                <div class="search-box">
                                    <form class="search-form">
                                        <div class="form-group position-relative">
                                            <input type="text" class="form-control search-input custom-field" placeholder="Search here..."/>
                                            <input type="submit" value="Seach" class="btn submit-search sm-voilet-btn d-block mt-2 ms-auto"/>
                                        </div>
                                    </form>
                                </div>
                            </div> -->
                            <!-- /Search Action -->
                            <div class="login-register-action d-flex align-items-center">
                               <div class="profile-action have-child main-btn-acc-whtlabel">
                                    <a href="javascript:void(0);"><img src="../../../../assets/images/Newui-image/user-profile.png" alt="user profile" class="img-fluid userprofileImg"/><span class="btn-icon-option"><i class="bi bi-caret-down-fill"></i></span></a>
                                    <div class="sub-child-menu sm-sub-menu-list">
                                        <div class="sub-child-list">
                                            <div class="links-creater profile-submenu">
                                                <a class="sub-menu-link ps-4" style="text-decoration: none;">Your account is where you can view all transactions, manage payment methods & recurring donations</a>

                                                <a (click)="signin()" class="sub-menu-link ps-4" style="cursor: pointer;">Sign in</a>

                                                <a (click)="signup()" class="sub-menu-link ps-4" style="cursor: pointer;">Sign Up</a>
                                            </div>

                                        </div>
                                    </div>
                               </div>

                            </div>
                        </div>
                    </div>
                    <!-- /All Action Menu Col-->
                </div>

            </div>
        </article>
    </section>
</header>
